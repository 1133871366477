.altPage {
  background-color: #00A479;
  color: #ffffff;
}

.altPage input[type="text"] {
  color: #201E16;
  border: 1px solid #ffffff;
  margin: 0 auto 10px;
}

.altPage input:auto-fill {
  border: 1px solid #ffffff;
  color: #201E16;
}

.altPage .formContainer input:-webkit-autofill,
.altPage .formContainer input:-webkit-autofill:hover,
.altPage .formContainer input:-webkit-autofill:focus {
  border: 1px solid #ffffff;
  color: #201E16;
  -webkit-text-fill-color: #000000;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

.altPage .formContainer ::placeholder {
  color: #201E16;
  opacity: 1;
  /* Firefox */
}

.altPage footer {
  margin-top: 0px;
}

.altPage button {
  background-color: #ffffff;
  color: #201E16;
  width: 75%;
}

.altPage ul {
  width: 230px;
  margin: 5px auto 10px;
}

li {
  list-style: none;
  margin: 2px 0;
  padding: 0;
  text-align: left;
  font-size: 18px;
}


li::before {
content: '';
display: inline-block;
height: 12px;
width: 20px;
margin: 0 8px;
background-image: url('../assets/img/form_tick.svg');
background-size: contain;
background-repeat: no-repeat;
background-position: left center;
}

.topBlock {
  background-color: #ffffff;
  margin: 0 auto;
  padding: 10px 0;

}

.playerWelcomeContainer {
  position: relative;
  width: 300px;
  margin: 0 auto 5px;
  text-align: left;
}

.playerWelcomeContainer .playerPhotoContainer {
  width: 140px;
  height: 140px;
  margin-left: 0;
  margin-right: 0;
}

.playerWelcomeContainer .playerPhotoContainer .playerPhotoMask {
  width: 190px;
  left: -25px;
}

.playerWelcomeContainer .playerPhotoContainer .playerPhotoBorder {
  width: 100%;
  height: 100%;
}

.playerWelcomeContainer .playerPhotoContainer .playerPhotoBorder img {
  filter: none;
} 


.playerNameBannerCenter {
  font-size: 37px;
  font-family: 'BebasNeue-Bold';
  color: #201E16;
  text-transform: uppercase;
  width: 286px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  margin: 5px auto 0;
  display: inline-block;
}

.playerEnteredText {
  font-size: 35px;
  font-family: 'BebasNeue-Bold';
  color: #201E16;
  text-transform: uppercase;
  width: 160px;
  position: absolute;
  top:82px;
  left:145px;
}

.playerEnteredSuccess {
  font-size: 35px;
  font-family: 'BebasNeue-Bold';
  color: #201E16;
  text-transform: uppercase;
  width: 160px;
  position: absolute;
  top:45px;
  left:145px;
}

.playerEnteredTableText {
  width: 100%;
  text-align: center;
  margin: 60px 0 20px;
}

.storyContainer {
  width: 87%;
  margin: 0px auto 5px;
  padding: 7px 0px;

}

.storyContainerHeader {
  font-size: 25px;
  font-family: 'BrandonGrotesqueWeb-Bold';
  margin: 20px 0 0;
}

.storyContainer img {
  width: inherit;
  height: auto;
  margin: 0 auto;
  display: block;
}

.checkbox {
  margin: 0 0 10px 0;
  text-align: left;
  display: inline-block;
  font-size: 17px;
}

.checkbox > * {
  vertical-align:middle;
}

.checkboxLabel {
  display: flex;
  align-items: center;
}

input[type=checkbox] {
  display: none;
}

input[type=checkbox]+label {
  background-image: url('../assets/img/checkbox_off.svg');
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  display: inline-block;
  padding: 0;
  margin: -5px 5px 0 0;
}

input[type=checkbox]:checked+label {
  background-image: url('../assets/img/checkbox_on.svg');
}