.playerPhotoIcon {
    margin: 20px auto;
    display: block;
}

.playerPhotoIcon img {
    width: 82%;
    height: auto;
    margin: 0 auto;
    display: block;
}