.playerPhotoContainer {
    width: 300px;
    height: 300px;
    text-align: center;
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    z-index: 2;
  }

  .playerPhotoContainer .videoContainer {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
   }
  
  .playerPhotoContainer video, .playerPhotoContainer img {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: -50px;
    margin: auto;
    width: 400px;
  }
  
  .playerPhotoBorder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left:0;
    right:0;
    margin: 0 auto;
  }

  .playerPhotoBorder img {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left:0;
    right:0;
    margin: 0 auto;
  }
  
  .playerPhotoMask {
    mask-image: url('../assets/img/photo_mask.png');
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    margin: 0 auto;
    position: relative;
    display:block;
  }