@font-face {
  font-family: "BrandonGrotesqueWeb-Reg";
  src: url("./assets/fonts/BrandonGrotesque-Regular.eot");
  src: url("./assets/fonts/BrandonGrotesque-Regular.woff2") format("woff2"),
  url("./assets/fonts/BrandonGrotesque-Regular.woff") format("woff"),
  url("./assets/fonts/BrandonGrotesque-Regular.ttf") format("truetype"),
  url("./assets/fonts/BrandonGrotesque-Regular.eot?#iefix") format("embedded-opentype");
}

@font-face {
  font-family: "BrandonGrotesqueWeb-Bold";
  src: url("./assets/fonts/BrandonGrotesque-Bold.eot");
  src: url("./assets/fonts/BrandonGrotesque-Bold.eot?#iefix") format("embedded-opentype"),
  url("./assets/fonts/BrandonGrotesque-Bold.woff2") format("woff2"),
  url("./assets/fonts/BrandonGrotesque-Bold.woff") format("woff"),
  url("./assets/fonts/BrandonGrotesque-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "BebasNeue-Regular";
  src: url("./assets/fonts/BebasNeueRegular.woff2") format("woff2"), url("./assets/fonts/BebasNeueRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BebasNeue-Bold";
  src: url("./assets/fonts/bebasneue_bold-webfont.eot");
  src: url("./assets/fonts/bebasneue_bold-webfont.woff2") format("woff2"), url("./assets/fonts/bebasneue_bold-webfont.woff") format("woff"), url("./assets/fonts/bebasneue_bold-webfont.ttf") format("truetype"),
    url("./assets/fonts/bebasneue_bold-webfont.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

* { 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body{
  margin: 0;
}

body {
  font-family: 'BrandonGrotesqueWeb-Reg', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #201E16;
  background-color: #ffffff;
}

.pageContainer {
  min-height: 100dvh;
  text-align: center;
}

.logoContainer {
  text-align: center;
  padding: 14px 0;
  background-color: #E5DFD6;
}

.logoContainer img {
  display: block;
  margin: 0 auto;
}

.button-complete .progressButton-dot{
  background: #E5DFD6;
  border: 2px solid #E5DFD6;
}

.button-complete:first-child::before {
  display: none;
}

.button-complete::before {
  content:"";
  background: #E5DFD6;
  border: none;
  height: 3px;
  position: absolute;
  left: calc(-50% + 10px);
  top: 8px;
  width: calc(100% - 20px);
}

.button-active .progressButton-dot {
  background: #00A479;
  border-width: 4px;
}

.button-active .progressLabel {
  color: #201E16;
}

.second-button {
  margin-top: 12px;
}


footer {
  margin: 20px 0 60px;
}

button {
  font-size: 25px;
  font-family: 'BrandonGrotesqueWeb-Bold';
  font-weight:500;
  letter-spacing: 1px;
  color: #ffffff;
  background-color: #00A479;
  border-radius: 30px;
  min-width: 228px;
  height: 60px;
  line-height: 60px;
  margin: 0 auto;
  padding: 0;
  border: 2px solid transparent;
  display: block;
}

button:disabled {
  opacity: 0.2;
}

.alt-button {
  background-color: #ffffff;
  color: #201E16;
  border: 2px solid #00A479;
  box-sizing: border-box;
}

.formContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
}

input[type="text"] {
  font-size: 25px;
  padding: 10px;
  border: 1px solid #201E16;
  width: 75%;
  box-sizing: border-box;
  color: #201E16;
  background-color: #ffffff;
  text-transform: uppercase;
  text-align: left;
}

input:auto-fill {
  border: 1px solid #201E16;
  color: #201E16;
  background-color: #ffffff;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #201E16;
    color: #201E16;
    background-color: #ffffff;
  -webkit-text-fill-color: #201E16;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: background-color 5000s ease-in-out 0s;
}

::placeholder {
  color: #9F9D95;
  opacity: 1; /* Firefox */
}

.errorContainer {
  width: 80%;
  margin: 40px auto;
}

.errorTitleText {
    font-size: 25px;
    font-family: 'BrandonGrotesqueWeb-Bold';
    font-weight:500;
    letter-spacing: 0.05em;
    color: #201E16;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    margin: 40px 0;
}

.errorText {
  text-align: center;
}

.clearfix:after {
  content:"";
  display:block;
  clear:both;
 }