.progressContainer {
    list-style: none;
    margin: 30px auto 10px;
    position: relative;
    width: 100%;
  }
  
  .progressButton {
    float:left;
    position: relative;
    width: 33%;
    text-align: center;
  }
  
  .progressButton::before {
    content:"";
    background: #E5DFD6;
    border:none;
    height: 2px;
    position: absolute;
    left: -50%;
    top: 9px;
    width: 100%;
  }
  
  .progressButton-border {
    width: 30px;
    height: 20px;
    position: relative;
  }
  
  .progressButton-dot {
    position: relative;
    width: 20px;
    height: 20px;
    background: #ffffff;
    border: 2px solid #E5DFD6;
    z-index: 2;
  }
  
  .progressLabel {
    margin: 5px 0;
    font-size: 15px;
    text-transform: uppercase;
    color: #9F9D95;
  }
  
  .progressButton-border, .progressButton-dot {
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    
  }